@import '~@paprika/tokens/lib/tokens.scss';

.asset-types {
  h1 button {
    margin-left: $space * 2;
  }

  .asset-types__card-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
