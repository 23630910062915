@import '~@paprika/tokens/lib/tokens.scss';

.attribute-types-page {
  h1 button {
    margin-left: $space * 2;
  }

  [role='columnheader'] {
    display: flex;
  }
}
