.horrendous-ui-wrapper {
  background: white;
  padding: 24px;

  table {
    text-align: left;
    width: 100%;
  }

  td {
    padding: 5px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .type-options-panel {
    margin: 8px;
    padding: 8px 16px;
    background-color: #eee;
  }

  .json-view {
    white-space: pre-wrap;
  }
}
