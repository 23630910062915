@import '~@paprika/tokens/lib/tokens.scss';

.checkbox-with-input {
  margin-bottom: $space;

  [data-pka-anchor='form-element.content'] {
    margin-bottom: 0;
  }
}

.checkbox-with-input__help-text {
  color: $color--black-lighten-20;
  margin-top: $space-sm;
  line-height: 1;
}
