@import '~@paprika/tokens/lib/tokens.scss';
@import '~@paprika/stylers/lib/helpers.scss';
@import url('//fonts.googleapis.com/css?family=Lato:300,400,700,400italic');

* {
  box-sizing: border-box;
}

html {
  opacity: 1;
  visibility: visible;
}

body {
  background-color: $color--black-lighten-80;
  color: $color--black;
  font-family: $font-family--default;
  font-size: font-scale();
  line-height: line-height-scale();
  margin: 0;
  overflow-x: hidden !important;
}

.app {
  font-family: $font-family--default;
}

.app-content {
  background: $color--black-lighten-80 url(./images/spangle.svg) repeat-x bottom -120px right 16px;
  color: $color--black;
  display: flex;
  flex-direction: column;
  font-size: font-scale(0);
  // Globar nav height: 40px
  min-height: calc(100vh - 40px);
  padding-left: 3 * $space;
  width: 100%;

  a {
    color: $text-color--link;
  }

  input {
    font-family: $font-family--default;
  }

  [data-pka-anchor='card'] {
    display: inline-block;
    margin: 0 $space * 3 $space * 3 0;
    text-decoration: none;

    [data-pka-anchor='card.footer'] {
      color: $color--black;
    }
  }
}

.master-library__container {
  align-items: stretch;
  display: flex;
}
