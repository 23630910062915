@import '~@paprika/tokens/lib/tokens.scss';
@import '~@paprika/stylers/lib/helpers.scss';

.side-navigation__trigger-button {
  font-size: font-scale(4);
  padding: $space;
}

.side-navigation {
  background-color: $color--white;
  width: 25%;

  .side-navigation__header-title {
    color: $color--black;
    margin-left: $space * 2;
    font-weight: 500;
    text-decoration: none;
  }

  .side-navigation-item {
    color: $color--black;
    font-size: font-scale(0);
    font-weight: 600;
    margin-bottom: $space * 3;
    width: 100%;

    .side-navigation-item__link {
      color: $color--black;
      margin-left: $space * 2;
      text-decoration: none;
    }
  }
}
