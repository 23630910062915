@import '~@paprika/tokens/lib/tokens.scss';

.styled-form-element {
  margin-bottom: $space * 2;

  label {
    margin-bottom: 0;
  }

  [data-pka-anchor='form-element.content'] {
    margin: 0;
  }
}
