@import '~@paprika/tokens/lib/tokens.scss';
@import '~@paprika/stylers/lib/helpers.scss';

.overview-panel__description {
  margin-bottom: $space * 2;

  label {
    margin-bottom: 0;
  }

  [data-pka-anchor='form-element.content'] {
    margin: 0;

    textarea {
      font-family: inherit;
      height: 160px;
    }
  }
}

.overview-panel__buttons {
  margin-bottom: $space * 2;

  [data-pka-anchor='confirmation'] {
    color: $color--black-pure;
    float: right;
  }
}

.overview-panel__footer-copy {
  color: $color--black-lighten-20;
  font-size: font-scale(-2);

  p {
    margin: 0;
    line-height: font-scale(1);
  }
}
