@import '~@paprika/tokens/lib/tokens.scss';

.asset-type__flex-wrapper {
  display: flex;

  .asset-type__overview-panel-wrapper {
    flex: 0 0 300px;
  }

  .asset-type__body-wrapper {
    flex: 1;
    margin-left: $space * 2;
  }
}

.view-card--spacer {
  height: $space * 3;
}

.asset-type__overlay [data-pka-anchor='button'] {
  margin-left: $space;
}
