@import '~@paprika/stylers/lib/helpers.scss';

.attribute-types__footer-buttons__archive-button {
  margin-left: $space * 2;
  font-size: font-scale(2);
  width: $space * 5;

  [data-pka-anchor='button.icon'] {
    margin-right: 0;
  }
}
