@import '~@paprika/tokens/lib/tokens.scss';
@import '~@paprika/stylers/lib/helpers.scss';

.recent-activity.recent-activity {
  color: $color--black;
  font-size: font-scale();
  font-style: normal;
  font-weight: bold;
  line-height: $space * 4;
  margin: 0;
}
