@import '~@paprika/tokens/lib/tokens.scss';

.widget-space {
  padding: $space-sm 0;
}

.widgets {
  display: flex;
}

.widgets__left {
  flex-basis: 65%;
  padding-right: $space;
}

.widgets__right {
  flex-basis: 35%;
}
