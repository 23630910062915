@import '~@paprika/tokens/lib/tokens.scss';
@import '~@paprika/stylers/lib/helpers.scss';

.dashboard-link-card {
  background-color: $color--white;
  border-radius: $card--border-radius;
  border: 1px solid $border--color;
  display: inline-flex;
  flex-direction: column;
  height: 125px;
  padding: $space * 2;
  position: relative;
  width: 280px;
  text-decoration: none;

  &:hover {
    background-color: $color--white;
    border: 2px solid $color--black-lighten-50;
  }

  &:focus {
    border: 1px solid $highlight--active--no-border--border-color;
    box-shadow: $highlight--active--no-border--box-shadow;
    outline: none;
  }

  .dashboard-link-card__icon {
    background-color: $color--black-lighten-10;
    border-radius: $card--border-radius;
    border-radius: 14px;
    color: $color--white;
    display: block;
    flex-shrink: 0;
    font-size: font-scale(3);
    font-weight: 600;
    height: $space * 5;
    line-height: 1.1;
    margin-bottom: $space;
    max-width: $space * 5;
    overflow: hidden;
    padding: 9px 9px $space $space;
    text-align: center;
  }

  .dashboard-link-card__title + .dashboard-link-card__count {
    display: block;
    height: $space-lg * 3;
    line-height: 1.1;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dashboard-link-card__title {
    color: $color--black;
    font-weight: 600;
    margin-bottom: $space;
  }

  .dashboard-link-card__count {
    color: $color--black-lighten-20;
    font-size: font-scale(-3);
  }
}
