@import url(//fonts.googleapis.com/css?family=Lato:300,400,700,400italic);
.side-navigation-item{color:#3f3d3c;font-size:16px;font-weight:600;margin-bottom:24px;width:100%}.side-navigation-item .side-navigation-item__link{color:#3f3d3c;margin-left:16px;text-decoration:none}

.side-navigation__trigger-button{font-size:26px;padding:8px}.side-navigation{background-color:#fff;width:25%}.side-navigation .side-navigation__header-title{color:#3f3d3c;margin-left:16px;font-weight:500;text-decoration:none}.side-navigation .side-navigation-item{color:#3f3d3c;font-size:16px;font-weight:600;margin-bottom:24px;width:100%}.side-navigation .side-navigation-item .side-navigation-item__link{color:#3f3d3c;margin-left:16px;text-decoration:none}

.master-library__side-bar{background-color:#fff;border:#d7d7d7 solid 1px;width:48px}.side-bar__menu-item{display:block;text-align:center;font-size:23px;text-decoration:none;color:#3f3d3c;margin-top:16px}

.dashboard-link-card{background-color:#fff;border-radius:6px;border:1px solid #d7d7d7;display:inline-flex;flex-direction:column;height:125px;padding:16px;position:relative;width:280px;text-decoration:none}.dashboard-link-card:hover{background-color:#fff;border:2px solid #bdbdbd}.dashboard-link-card:focus{border:1px solid #276cf5;box-shadow:0 0 0 2px #99cbfc;outline:none}.dashboard-link-card .dashboard-link-card__icon{background-color:#575757;border-radius:6px;border-radius:14px;color:#fff;display:block;flex-shrink:0;font-size:23px;font-weight:600;height:40px;line-height:1.1;margin-bottom:8px;max-width:40px;overflow:hidden;padding:9px 9px 8px 8px;text-align:center}.dashboard-link-card .dashboard-link-card__title+.dashboard-link-card__count{display:block;height:36px;line-height:1.1;max-width:100%;overflow:hidden;text-overflow:ellipsis}.dashboard-link-card .dashboard-link-card__title{color:#3f3d3c;font-weight:600;margin-bottom:8px}.dashboard-link-card .dashboard-link-card__count{color:#717171;font-size:11px}

.dashboard-container{width:100%;height:100vh}.dashboard-container>h1{font-weight:500;padding-left:8px}.dashboard-container .dashboard-apps-section{display:flex}.dashboard-container .dashboard-apps-section .dashboard-link-card{margin:0 16px 16px 0}

.error-page-wrapper{display:flex;flex-direction:column;justify-content:center;align-items:center;width:700px;margin:80px auto}.error-page_card-wrapper{width:700px;display:flex;flex-direction:column;justify-content:center;align-items:center;margin-top:32px;text-align:center;padding:32px}.error-page-logo{width:50%}.error-page-footer{color:#717171}.error-page-link{text-decoration:none;color:#0063c5}

.asset-type-attribute-types-page__data-grid-header{display:inline-flex;align-items:center;padding-left:8px}.asset-type-attribute-types-page__data-grid-header .data-grid-header__icon{color:#575757;margin-right:8px}.asset-type-attribute-types-page__data-grid-header .data-grid-header__icon--rotate{-webkit-transform:rotate(270deg);transform:rotate(270deg)}

.asset-type-attribute-types-page__is-global-content{text-align:center}

.asset-type-attribute-types-page__related-asset-types-content{text-align:right}

.styled-form-element{margin-bottom:16px}.styled-form-element label{margin-bottom:0}.styled-form-element [data-pka-anchor='form-element.content']{margin:0}

.date-time-container{max-width:106px;padding:0 8px}

.widget-space{padding:4px 0}.widgets{display:flex}.widgets__left{flex-basis:65%;padding-right:8px}.widgets__right{flex-basis:35%}

.recent-activity.recent-activity{color:#3f3d3c;font-size:16px;font-style:normal;font-weight:bold;line-height:32px;margin:0}

.attribute-types__footer-buttons__archive-button{margin-left:16px;font-size:20px;width:40px}.attribute-types__footer-buttons__archive-button [data-pka-anchor='button.icon']{margin-right:0}

.attribute-types__footer-buttons__remove-asset-type{flex-grow:1}

.attribute-types__footer-buttons__add-asset-type{flex-grow:1}

.attribute-types__footer-buttons{width:100%;display:flex;justify-content:space-between;align-items:center}

.attribute-types-page h1 button{margin-left:16px}.attribute-types-page [role='columnheader']{display:flex}

.checkbox-with-input{margin-bottom:8px}.checkbox-with-input [data-pka-anchor='form-element.content']{margin-bottom:0}.checkbox-with-input__help-text{color:#717171;margin-top:4px;line-height:1}

.overview-panel__description{margin-bottom:16px}.overview-panel__description label{margin-bottom:0}.overview-panel__description [data-pka-anchor='form-element.content']{margin:0}.overview-panel__description [data-pka-anchor='form-element.content'] textarea{font-family:inherit;height:160px}.overview-panel__buttons{margin-bottom:16px}.overview-panel__buttons [data-pka-anchor='confirmation']{color:#000;float:right}.overview-panel__footer-copy{color:#717171;font-size:13px}.overview-panel__footer-copy p{margin:0;line-height:18px}

.asset-type__flex-wrapper{display:flex}.asset-type__flex-wrapper .asset-type__overview-panel-wrapper{flex:0 0 300px}.asset-type__flex-wrapper .asset-type__body-wrapper{flex:1 1;margin-left:16px}.view-card--spacer{height:24px}.asset-type__overlay [data-pka-anchor='button']{margin-left:8px}

.asset-types h1 button{margin-left:16px}.asset-types .asset-types__card-footer{display:flex;flex-direction:row;justify-content:space-between;align-items:center}

.assets-list{background-color:white;padding:0 16px;margin:16px 8px}.assets-list .asset-types-grid{display:flex;flex-wrap:wrap;justify-content:flex-start;align-content:flex-start}.assets-list .asset-types-grid a{width:300px;text-decoration:none;display:block;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.assets-list .asset-types-grid .asset-type-card{display:flex;flex-direction:column;justify-content:space-between;height:150px;border:1px solid lightgray;border-radius:4px;margin:8px;align-items:center}.assets-list .asset-types-grid .asset-type-card:hover{background-color:#eee}.assets-list .asset-types-grid .asset-type-card:active{background-color:#e4e4e4}.assets-list .asset-types-grid .asset-type-card h3,.assets-list .asset-types-grid .asset-type-card span{display:block;margin:4px auto;text-overflow:ellipsis;overflow:hidden;max-width:250px}

.horrendous-ui-wrapper{background:white;padding:24px}.horrendous-ui-wrapper table{text-align:left;width:100%}.horrendous-ui-wrapper td{padding:5px;max-width:250px;overflow:hidden;text-overflow:ellipsis}.horrendous-ui-wrapper select{max-width:350px}.horrendous-ui-wrapper .json-view{white-space:pre-wrap}

.horrendous-ui-wrapper{background:white;padding:24px}.horrendous-ui-wrapper table{text-align:left;width:100%}.horrendous-ui-wrapper td{padding:5px;max-width:250px;overflow:hidden;text-overflow:ellipsis}.horrendous-ui-wrapper .type-options-panel{margin:8px;padding:8px 16px;background-color:#eee}.horrendous-ui-wrapper .json-view{white-space:pre-wrap}

.label-row .form-input{display:inline-block;margin-right:8px}.add-label-controls{margin-bottom:16px}.add-label-controls button{margin-right:8px}

.horrendous-ui-attribute-types table{text-align:left;width:100%;background-color:white;padding:24px}.horrendous-ui-attribute-types tr:nth-child(even){background-color:#eee}.horrendous-ui-attribute-types td{padding:5px;max-width:250px;overflow:hidden;text-overflow:ellipsis}.horrendous-ui-attribute-types .type-options-panel{width:50%;background-color:white;padding:8px 16px;margin:8px}.horrendous-ui-attribute-types .switch-description{padding-left:8px}.horrendous-ui-attribute-types [data-testid="attribute-type-widget.select.allow-multiple"]{display:none}

*{box-sizing:border-box}html{opacity:1;visibility:visible}body{background-color:#f7f7f7;color:#3f3d3c;font-family:"Lato","Helvetica Neue",Helvetica,Arial,sans-serif;font-size:16px;line-height:1.4;margin:0;overflow-x:hidden !important}.app{font-family:"Lato","Helvetica Neue",Helvetica,Arial,sans-serif}.app-content{background:#f7f7f7 url(/static/media/spangle.0ab9ffc9.svg) repeat-x bottom -120px right 16px;color:#3f3d3c;display:flex;flex-direction:column;font-size:16px;min-height:calc(100vh - 40px);padding-left:24px;width:100%}.app-content a{color:#0063c5}.app-content input{font-family:"Lato","Helvetica Neue",Helvetica,Arial,sans-serif}.app-content [data-pka-anchor='card']{display:inline-block;margin:0 24px 24px 0;text-decoration:none}.app-content [data-pka-anchor='card'] [data-pka-anchor='card.footer']{color:#3f3d3c}.master-library__container{align-items:stretch;display:flex}

body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

