.horrendous-ui-attribute-types {
  table {
    text-align: left;
    width: 100%;
    background-color: white;
    padding: 24px;
  }

  tr:nth-child(even) {
    background-color: #eee;
  }

  td {
    padding: 5px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .type-options-panel {
    width: 50%;
    background-color: white;
    padding: 8px 16px;
    margin: 8px;
  }

  .switch-description {
    padding-left: 8px;
  }

  [data-testid="attribute-type-widget.select.allow-multiple"] {
    display: none
  }
}
