@import '~@paprika/tokens/lib/tokens.scss';

.label-row .form-input {
  display: inline-block;
  margin-right: $space;
}
.add-label-controls {
  margin-bottom: $space * 2;

  button {
    margin-right: $space;
  }
}
