.assets-list {
  background-color: white;
  padding: 0 16px;
  margin: 16px 8px;

  .asset-types-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;

    a {
      width: 300px;
      text-decoration: none;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .asset-type-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 150px;
      border: 1px solid lightgray;
      border-radius: 4px;
      margin: 8px;
      align-items: center;

      &:hover {
        background-color: #eee;
      }

      &:active {
        background-color: #e4e4e4;
      }

      h3,
      span {
        display: block;
        margin: 4px auto;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 250px;
      }
    }
  }
}
