@import '~@paprika/tokens/lib/tokens.scss';
@import '~@paprika/stylers/lib/helpers.scss';

.error-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
  margin: $space * 10 auto;
}

.error-page_card-wrapper {
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: $space * 4;
  text-align: center;
  padding: $space * 4;
}

.error-page-logo {
  width: 50%;
}

.error-page-footer {
  color: $color--black-lighten-20;
}

.error-page-link {
  text-decoration: none;
  color: $text-color--link;
}
