@import '~@paprika/tokens/lib/tokens.scss';

.asset-type-attribute-types-page__data-grid-header {
  display: inline-flex;
  align-items: center;

  padding-left: $space;

  .data-grid-header__icon {
    color: $color--black-lighten-10;
    margin-right: $space;
  }

  .data-grid-header__icon--rotate {
    transform: rotate(270deg);
  }
}
