@import '~@paprika/tokens/lib/tokens.scss';
@import '~@paprika/stylers/lib/helpers.scss';

.side-navigation-item {
  color: $color--black;
  font-size: font-scale(0);
  font-weight: 600;
  margin-bottom: $space * 3;
  width: 100%;

  .side-navigation-item__link {
    color: $color--black;
    margin-left: $space * 2;
    text-decoration: none;
  }
}
