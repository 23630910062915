@import '~@paprika/tokens/lib/tokens.scss';
@import '~@paprika/stylers/lib/helpers.scss';

.master-library__side-bar {
  background-color: $color--white;
  border: $color--black-lighten-60 solid 1px;
  width: $space * 6;
}

.side-bar__menu-item {
  display: block;
  text-align: center;
  font-size: font-scale(3);
  text-decoration: none;
  color: $color--black;
  margin-top: $space * 2;
}
