@import '~@paprika/tokens/lib/tokens.scss';
@import '~@paprika/stylers/lib/helpers.scss';

.dashboard-container {
  width: 100%;
  height: 100vh;

  > h1 {
    font-weight: 500;
    padding-left: $space;
  }

  .dashboard-apps-section {
    display: flex;

    .dashboard-link-card {
      margin: 0 $space * 2 $space * 2 0;
    }
  }
}
