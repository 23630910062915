//Just quick styles non dependent on libs

.horrendous-ui-wrapper {
  background: white;
  padding: 24px;

  table {
    text-align: left;
    width: 100%;
  }

  td {
    padding: 5px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  select {
    max-width: 350px;
  }

  .json-view {
    white-space: pre-wrap;
  }
}
